import React from "react"

// Layout
import Layout from "../components/layout"
// Components
import Menu from "../components/home/menu"
import Breadcrumb from "../components/elements/breadcrumb"
import VideoGrid from "../components/elements/video-grid"
import AudioGrid from "../components/elements/audio-grid"
import PhotoGrid from "../components/elements/photo-grid"
// Hooks
import { useAsmetHome } from "../hooks/use-asmet-home"
import { useAsmetVideos } from "../hooks/use-asmet-videos"
import { useAsmetPhotos } from "../hooks/use-asmet-photos"
import { useAsmetAudios } from "../hooks/use-asmet-audios"

const MediaPage = () => {
  const asmetHome = useAsmetHome()
  const allVideos = useAsmetVideos()
  const allPhotos = useAsmetPhotos()
  const allAudios = useAsmetAudios()

  const crumbs = [{ path: `/`, label: "Media" }]

  let flattenAudios = []
  allAudios.forEach(el => {
    flattenAudios = flattenAudios.concat(Object.values(el))
  })

  let flattenFotos = []
  allPhotos.forEach(el => {
    flattenFotos = flattenFotos.concat(Object.values(el))
  })

  let flattenVideos = []
  allVideos.forEach(el => {
    flattenVideos = flattenVideos.concat(Object.values(el))
  })

  return (
    <Layout meta={{ og_titulo: "Multimedia" }}>
      <div id="asm-template-header" className="contanier-fluid pt-3 pb-3">
        <div className="container">
          <div className="row">
            <div className="col">
              <Breadcrumb crumbs={crumbs} />
              <h3 className="text-white-alpha text-left pt-4">Multimedia</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="asm-media-main container my-5">
        <div className="row justify-content-center">
          <div className="col-12 col-md-6">
            <ul className="nav nav-tabs nav-justified border-0" id="mediaTab">
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link py-0 px-1 border-0 active"
                  id="videosTab"
                  data-toggle="tab"
                  href="#mediaVideos"
                  role="tab"
                  aria-controls="mediaVideos"
                  aria-selected="true"
                >
                  <h3 className="text-600 py-3">Videos</h3>
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link py-0 px-1 border-0"
                  id="audiosTab"
                  data-toggle="tab"
                  href="#mediaAudios"
                  role="tab"
                  aria-controls="mediaAudios"
                  aria-selected="false"
                >
                  <h3 className="text-600 py-3">Audios</h3>
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link py-0 px-1 border-0"
                  id="photosTab"
                  data-toggle="tab"
                  href="#mediaPhotos"
                  role="tab"
                  aria-controls="mediaPhotos"
                  aria-selected="false"
                >
                  <h3 className="text-600 py-3">Fotos</h3>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="tab-content flex-grow-1 mt-4" id="mediaTabContent">
          <div
            className="tab-pane h-100 fade show active"
            id="mediaVideos"
            role="tabpanel"
            aria-labelledby="videosTab"
          >
            <VideoGrid allVideos={flattenVideos} />
          </div>
          <div
            className="tab-pane h-100 fade"
            id="mediaAudios"
            role="tabpanel"
            aria-labelledby="audiosTab"
          >
            <AudioGrid allAudios={flattenAudios} />
          </div>
          <div
            className="tab-pane h-100 fade"
            id="mediaPhotos"
            role="tabpanel"
            aria-labelledby="photosTab"
          >
            <PhotoGrid allPhotos={flattenFotos} />
          </div>
        </div>
      </div>
      <hr />
      <Menu menuSecundario={asmetHome.menu_secundario} />
    </Layout>
  )
}

export default MediaPage
